<template>
  <div class="fd-w-full" style="min-height: 500px;">
    <div class="fd-absolute fd-right-4 top--custom">
      <b-button @click="openBrowser" variant="outline-primary" size="sm" style="padding: 4px 25px !important;">
        <span class="fd-text-lg  fd-mr-2 fd-font-thin">+</span>
        <span>{{ $t('global.upload') }}</span>
      </b-button>
      <input type="file" ref="file" @change="selectFile" style="display: none">
    </div>
<!--    <div-->
<!--      v-show="uploader && uploader.dropActive"-->
<!--      class="drop-active fd-absolute fd-mt-5 fd-w-full fd-h-full fd-flex fd-items-center fd-justify-center"-->
<!--      style="z-index: 999; background-color: #D9D9D9; border: 3px dashed #828282;"-->
<!--    >-->
<!--      <h3 class="fd-text-3xl fd-font-bold" style="color: #828282">Drop files to upload</h3>-->
<!--    </div>-->
<!--    <file-upload-->
<!--      @input="updateValue"-->
<!--      :multiple="true"-->
<!--      :post-action="postAction"-->
<!--      :drop="true"-->
<!--      :drop-directory="true"-->
<!--      :extensions="extensions"-->
<!--      @input-file="inputFile"-->
<!--      v-model="files"-->
<!--      ref="upload"-->
<!--      :name="'file'"-->
<!--      :headers="getHeaders"-->
<!--    >-->
<!--    </file-upload>-->

    <span class="fd-block fd-border-t fd-border-theme-5 fd-mt-4 fd-mb-5"></span>
    <div v-if="options.length || files.length">
      <div v-if="idSelected.length" class="fd-w-full fd-flex fd-mb-5 fd-items-center fd-justify-between">
        <b-form-checkbox
          v-model="all"
        >
          <span>{{ $t('global.selectAll') }}</span>
        </b-form-checkbox>
        <span @click="toggleConfirmDialogue" class="fd-block fd-bg-theme-4 fd-p-2 fd-rounded fd-cursor-pointer">
        <SvgLoader :name="'trashIcon'"/>
      </span>
      </div>

      <div ref="masonryGrid" class="masonry-grid fd-w-full">
        <div class="grid-sizer"></div>
        <div
          v-for="(option, index) in options"
          :key="index"
          class="grid-item"
        >
          <div class="fd-relative">
            <b-form-checkbox
              v-if="option.url"
              style="position: absolute !important; top: .6rem; right: .1rem;"
              :value="option.id"
              v-model="idSelected"
            />
            <img :src="option.url" alt="project photo">
          </div>
        </div>

        <div
          v-for="(file, index) in files"
          :key="index + 50000"
          class="fd-relative grid-item fd-overflow-hidden"
        >
          <div v-if="file.progress < 100" class="fd-absolute" style="top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0, .7)">
            <div class="fd-w-3/4 fd-mx-auto" style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
              <b-progress :value="file.progress" :max="100" show-progress animated class="progress-bar-success"></b-progress>
            </div>
          </div>
          <img :src="file.blob" alt="">
        </div>
      </div>
    </div>

    <div v-else class="fd-mt-14 fd-flex fd-justify-center fd-text-sm fd-text-gray-600">
      <span>{{ $t('global.thereIsNoMedia') }}</span>
    </div>

    <ConfirmDialogue @remove="remove" />
    <b-toast id="example-toast" title="BootstrapVue" static></b-toast>
  </div>
</template>

<script>
import Masonry from 'masonry-layout'
import Imagesloaded from 'imagesloaded'
import { BProgress, BToast, BFormCheckbox, BButton } from 'bootstrap-vue'
import ConfirmDialogue from "@/views/components/global/ConfirmDialogue";

export default {
  name: "Plan",
  components: {ConfirmDialogue, BProgress, BToast, BFormCheckbox, BButton },
  data() {
    return {
      files: [],
      uploader: false,
      extensions: 'gif,jpg,jpeg,png,webp',
      masonry: null,
      all: false,
      idSelected: [],
      selectedFile: null,
      name: null,
      type: null,
    }
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    postAction: {
      type: String,
      required: true
    }
  },
  async mounted() {
    await this.$nextTick(async () => {
      await this.setupMasonry()
    })
  },
  computed: {
    getHeaders() {
      return {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }
  },
  watch: {
    selectedFile: {
      handler() {
        if (!this.selectedFile) return
        const fileName = this.selectedFile.name.split('.')
        this.name = fileName[0]
        this.type = 'plan'

        let formData = new FormData()
        formData.append('media', this.selectedFile)
        formData.append('name', this.name)
        formData.append('type', this.type)

        let URL = window.URL || window.webkitURL
        const uuid = this.generateUUID()
        this.files.push({
          id: uuid,
          blob: URL.createObjectURL(this.selectedFile),
          progress: 0
        })
        let file = this.files.find(file => {
          if (file.id === uuid) {
            return file
          }
        })

        const config = {
          onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            file.progress = parseInt(percentComplete * 100);
          }
        }

        this.$store.dispatch('project/uploadFile', {
          data: formData,
          url: this.postAction,
          config
        })
          .then(() => {
            this.$emit('refreshData')
            this.$nextTick(() => {
              this.files = []
            })
          })
          .catch(() => {
            this.files = this.files.filter(item => item.id !== uuid)
            this.makeToast()
          })
          .finally(() => {
            this.selectedFile = null
          })
      },
      deep: true
    },
    options: {
      async handler() {
        await this.$nextTick(async () => {
          await this.setupMasonry()
        })
      },
      deep: true
    },
    files: {
      handler(newVal) {
        this.$nextTick(() => {
          this.masonry.reloadItems();
          this.masonry.layout();
        })
      },
      deep: true
    },
    all: {
      handler() {
        if (!this.all) {
          this.idSelected = []
          return
        }
        this.options.forEach(item => {
          if (!this.idSelected.includes(item.id)) {
            this.idSelected.push(item.id)
          }
        })
      }
    }
  },
  methods: {
    async setupMasonry() {
      const gridElement = this.$refs.masonryGrid
      this.masonry = new Masonry(gridElement, {
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true
      });
      this.setupImagesLoaded()
      // this.uploader = this.$refs.upload;
    },
    setupImagesLoaded() {
      const gridElement = this.$refs.masonryGrid
      new Imagesloaded(gridElement).on('progress', () => {
        this.masonry.layout();
      })
    },
    openBrowser() {
      this.$refs.file.click()
    },

    selectFile() {
      this.selectedFile = this.$refs.file.files[0]
      this.$refs.file.value = ''
      // this.newImageUrl = URL.createObjectURL(this.image)
    },
    updateValue(value) {
      this.files = value
    },
    // inputFile(newFile, oldFile) {
    //   if (newFile && !oldFile) {
    //     // Add file
    //     newFile.blob = ''
    //     let URL = window.URL || window.webkitURL
    //     if (URL && URL.createObjectURL) {
    //       newFile.blob = URL.createObjectURL(newFile.file)
    //     }
    //   }
    //
    //   if (newFile && oldFile) {
    //     // Update file
    //
    //     // Start upload
    //     if (newFile.active !== oldFile.active) {
    //       console.log('Start upload', newFile.active, newFile)
    //
    //       // min size
    //       if (newFile.size >= 0 && newFile.size < 100 * 1024) {
    //         newFile = this.$refs.upload.update(newFile, {error: 'size'})
    //       }
    //     }
    //
    //     // Upload progress
    //     console.log('progress1111', newFile.progress, oldFile.progress)
    //     if (newFile.progress !== oldFile.progress) {
    //       console.log('progress', newFile.progress, newFile)
    //     }
    //
    //     // Upload error
    //     if (newFile.error !== oldFile.error) {
    //       this.$refs.upload.remove(newFile)
    //       this.makeToast()
    //       console.log('error', newFile.error, newFile)
    //     }
    //
    //     // Uploaded successfully
    //     if (newFile.success !== oldFile.success) {
    //       console.log('success', newFile.success, newFile)
    //     }
    //   }
    //
    //   if (!newFile && oldFile) {
    //     // Remove file
    //
    //     // Automatically delete files on the server
    //     if (oldFile.success && oldFile.response.id) {
    //       // $.ajax({
    //       //   type: 'DELETE',
    //       //   url: '/file/delete?id=' + oldFile.response.id,
    //       // });
    //     }
    //   }
    //
    //   // Automatic upload
    //   if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
    //     if (!this.$refs.upload.active) {
    //       this.$refs.upload.active = true
    //     }
    //   }
    // },
    makeToast() {
      this.$bvToast.toast('Toast body content', {
        title: 'Error',
        toaster: 'b-toaster-bottom-right',
        variant: 'danger',
        solid: true
      })
    },
    toggleConfirmDialogue() {
      this.$root.$emit("bv::toggle::modal", "confirmDialogue");
    },
    async remove() {
      let ids = ''
      this.idSelected.forEach((item, index) => {
        if (index === 0) {
          ids = 'ids[]=' + item
        } else {
          ids = ids + '&ids[]=' + item
        }
      })

      this.$store.dispatch('project/removeMedia', {
        url: this.postAction,
        data: {
          ids
        }
      }).then(() => {
        this.$emit('refreshData')
        this.idSelected = []
      })
      this.toggleConfirmDialogue()
    },
    generateUUID() {
      let d = new Date().getTime();
      let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;
        if(d > 0){
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
        } else {
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-sizer,
.grid-item { width: 50%; }
.grid-item {
  border-radius: 8px;

  img {
    width: 100%;
    padding: 5px;
    border-radius: 8px;
  }
}

.top--custom {
  top: 0.75rem
}
@media screen and (max-width: 1199px) {
  .top--custom {
    top: 2.6rem
  }
}
</style>