import { render, staticRenderFns } from "./Photos.vue?vue&type=template&id=309bf984&scoped=true&"
import script from "./Photos.vue?vue&type=script&lang=js&"
export * from "./Photos.vue?vue&type=script&lang=js&"
import style0 from "./Photos.vue?vue&type=style&index=0&id=309bf984&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "309bf984",
  null
  
)

export default component.exports